import React, { useEffect, lazy, Suspense, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment-timezone';
import { ConfigProvider } from 'antd';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import frFR from 'antd/lib/locale/fr_FR';
import enUS from 'antd/lib/locale/en_US';

import ProtectedRoute from '../../auth/protectedRoute';

import { progressSelector } from 'sharedlibs/lib/redux/selectors/booking';
import { changeLocale, setQueryParams } from 'sharedlibs/lib/redux/actions';

import Loading from '../../components/Loading';

import 'antd/dist/antd.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import './styles.css';

import { setupIntercom } from '../../intercom-setup';
import zh_CN from 'antd/lib/locale/zh_CN';
import { getQuote } from 'sharedlibs/lib/redux/actions/tempBooking';

const TrackingEmail = lazy(() => import('../TrackingEmail'));
const ConfirmationPage = lazy(() => import('../ConfirmationPage'));
const EditBookingPage = lazy(() => import('../EditBookingPage'));
const LocationsPage = lazy(() => import('../LocationsPage'));
const LocationsDetailsPage = lazy(() => import('../LocationsDetailsPage'));
const AddOnsPage = lazy(() => import('../AddOnsPage'));
const MoveTypePage = lazy(() => import('../MoveTypePage'));
const NotFoundPage = lazy(() => import('../NotFoundPage'));
const PaymentPage = lazy(() => import('../PaymentPage'));
const ProfileBookingsPage = lazy(() => import('../ProfileBookingsPage'));
const ProfilePage = lazy(() => import('../ProfilePage'));
const ProfileSettingsPage = lazy(() => import('../ProfileSettingsPage'));
const SchedulePage = lazy(() => import('../SchedulePage'));
const ThankYouPage = lazy(() => import('../ThankYouPage'));
const UserInfoPage = lazy(() => import('../UserInfoPage'));
const ViewBookingPage = lazy(() => import('../ViewBookingPage'));
const localeValues = { 'en': enUS, 'fr': frFR, 'zh': zh_CN };

function App() {
  const { isLoading: isAuth0Loading } = useAuth0();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const isQuoteLoading = useSelector(
    ({ tempBookingReducer }) => tempBookingReducer.getQuoteLoading
  );
  const progress = useSelector(({ bookingReducer }) =>
    progressSelector(bookingReducer)
  );
  const editProgress = useSelector(({ tempBookingReducer }) =>
    progressSelector(tempBookingReducer)
  );
  // determine the locale to supply to antd components
  const locale = useSelector(({ languageReducer }) => languageReducer.locale);
  const timezone = useSelector(({ geoReducer }) => geoReducer.timezone);

  useEffect(() => {
    moment.tz.setDefault(timezone);
  }, [timezone]);

  useEffect(() => {
    setupIntercom('q8mula5v', locale);
  }, [locale]);

  useEffect(() => {
    const changeLanguage = () => {
      const lang = new URLSearchParams(search).get('lang');
      if (['zh', 'fr', 'en'].includes(lang)) {
        dispatch(changeLocale(lang));
      }
    };
    return changeLanguage();
  }, [dispatch, search]);

  /**
   * useEffect to add a callback subscription once rehydration is completed.
   * Only specific query parameters should be saved.
   */
  useEffect(() => {
    const params = new URLSearchParams(search, [search]);
    if (params.toString()) {
      const validParams = [
        'discount',
        'pickupFullAddress',
        'pickupApt',
        'pickupFloor',
        'pickupParking',
        'pickupAccessibility',
        'dropoffFullAddress',
        'dropoffApt',
        'dropoffParking',
        'dropoffFloor',
        'dropoffAccessibility',
        'skipLeadForm',
      ];
      const filteredQueries = {};
      params.forEach((value, key) =>
        validParams.includes(key) ? (filteredQueries[key] = value) : null
      );
      dispatch(setQueryParams(filteredQueries));
    }
  }, []);

  // Fetch quote if needed
  useEffect(() => {
    const fetchQuote = async () => {
      const params = new URLSearchParams(search);
      const quoteId = params.get('quoteId');
      if (quoteId) {
        try {
          await dispatch(getQuote(quoteId));
          // Remove quoteId from url
          params.delete('quoteId');
          window.history.replaceState(
            {},
            '',
            params.length
              ? `${window.location.pathname}?${params}`
              : window.location.pathname
          );
        } catch (error) {
          console.log(error);
        }
      }
    };
    fetchQuote();
  }, []);

  if (isAuth0Loading || isQuoteLoading) {
    return <Loading />;
  }

  const getRedirect = (Page, mustPassProcesses = [0, 1, 2]) => {
    const canPass = mustPassProcesses.every((step) => {
      if (progress[step] !== 'complete') {
        return false;
      }
      return true;
    });
    return canPass ? <Page /> : <Redirect to="/" />;
  };

  const getRedirectEdit = (Page, mustPassProcesses = [0, 1, 2]) => {
    const canPass = mustPassProcesses.every((step) => {
      if (editProgress[step] !== 'complete') {
        return false;
      }
      return true;
    });
    return canPass ? <Page /> : <Redirect to="/" />;
  };
  return (
    <ConfigProvider locale={localeValues[locale]}>
      <Router>
        <Suspense fallback={<Loading />}>
          <Switch>
            <Route
              path="/email-redirect"
              exact
              component={TrackingEmail}
            />
            <Route
              path="/confirmation"
              exact
              component={ConfirmationPage}
            />
            <Route
              exact
              path="/locations"
              component={LocationsPage}
            />
            <Route
              exact
              path="/locations/:hexData"
              component={LocationsPage}
            />
            <Route
              path="/move-type"
              exact
            >
              {getRedirect(MoveTypePage, [0])}
            </Route>
            <Route
              path="/move-type/:bookingId"
              exact
            >
              {getRedirectEdit(MoveTypePage, [0])}
            </Route>
            <Route
              path="/locations-details"
              exact
            >
              {getRedirect(LocationsDetailsPage, [0, 1])}
            </Route>
            <Route
              path="/locations-details/:bookingId"
              exact
            >
              {getRedirectEdit(LocationsDetailsPage, [0, 1])}
            </Route>
            <Route
              path="/add-ons"
              exact
            >
              {getRedirect(AddOnsPage, [0, 1])}
            </Route>
            <Route
              path="/add-ons/:bookingId"
              exact
            >
              {getRedirectEdit(AddOnsPage, [0, 1])}
            </Route>
            <ProtectedRoute
              path="/profile/bookings/:bookingId"
              exact
              component={EditBookingPage}
            />
            <Route
              path="/booking/view"
              exact
              component={ViewBookingPage}
            />
            <Route
              path="/booking/view/:hexData"
              exact
              component={ViewBookingPage}
            />
            <Route
              path="/payment"
              exact
            >
              {getRedirect(PaymentPage, [0, 1, 2])}
            </Route>
            <Route
              path="/payment/:bookingId"
              exact
            >
              {getRedirectEdit(PaymentPage, [0, 1, 2])}
            </Route>
            <ProtectedRoute
              path="/profile"
              exact
              component={ProfilePage}
            />
            <ProtectedRoute
              path="/profile/bookings"
              exact
              component={ProfileBookingsPage}
            />
            <ProtectedRoute
              path="/profile/settings"
              exact
              component={ProfileSettingsPage}
            />
            <Route
              path="/schedule"
              exact
            >
              {getRedirect(SchedulePage, [0, 1])}
            </Route>
            <Route
              path="/schedule/:bookingId"
              exact
            >
              {getRedirectEdit(SchedulePage, [0, 1])}
            </Route>
            <Route
              exact
              path="/thank-you"
              component={ThankYouPage}
            />
            <ProtectedRoute
              path="/user-info"
              exact
              component={UserInfoPage}
            />
            <Route
              path="/"
              exact
            >
              <Redirect
                to={{ pathname: '/locations/', search: window.location.search }}
              />
            </Route>
            <Route component={NotFoundPage} />
          </Switch>
        </Suspense>
      </Router>
    </ConfigProvider>
  );
}

export default App;
