import axios from 'axios';

export default class BookingService {
  sendBookingData(bookingData) {
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/book`, bookingData);
  }

  /**
   * Estimation formula for duration of the move
   *   based on https://dev-movemate.atlassian.net/wiki/spaces/MOV/pages/39878839/Estimation+Formula
   * @param {object} bookingData the necessary information required to complete the formula
   * - moveDuration {number}: duration between pickup and dropoff locations
   * - cuft {number}: volume of items to move in cubic feet
   * - mateCount {number}: number in set {1,2,3} indicating total amount of mates in the move
   * - stairs {number}: Total number of floors if the elevator equals to 0
   * - location_details [list[dict]]]: List of values of each location:
                            dict{accessibility, parking, floors}
                            accessibility: {0-2} indicating 0 No stairs or elevators -> RC
                                                            1 Has Stairs
                                                            2 Has an Elevator
                            parking[int]: {0-1} indicating 0 location DO NOT have parking
                                                           1 location has parking
                            floors [int]: Total number of floors on location
   * - clientAsMate {boolean}: Client will work as a mate
   * - justMates {boolean}: Do not need a vehicle, only require mates
   * - movingDate {float}: The moving date represented in milliseconds
   * - discountCode {string}: The discount code string
   * - tax {float}: tax rate for the specific location
   */
  fetchEstimate(bookingData) {
    return axios
      .request({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}/booking/estimate`,
        data: bookingData,
      })
      .then((resp) => resp.data);
  }

  /**
   * Returns the estimated duration of the move
   *   based on https://dev-movemate.atlassian.net/wiki/spaces/MOV/pages/39878839/Estimation+Formula
   * @param {object} bookingData the necessary information required to complete the formula
   * - moveDuration {number}: duration between pickup and dropoff locations
   * - cuft {number}: volume of items to move in cubic feet
   * - mateCount {number}: number in set {1,2,3} indicating total amount of mates in the move
   * - location_details [list[dict]]]: List of values of each location:
                            dict{accessibility, parking, floors}
                            accessibility: {0-2} indicating 0 No stairs or elevators -> RC
                                                            1 Has Stairs
                                                            2 Has an Elevator
                            parking[int]: {0-1} indicating 0 location DO NOT have parking
                                                           1 location has parking
                            floors [int]: Total number of floors on location
   * - rideAlong {boolean}: Client will be riding along
   * - clientAsMate {boolean}: Client will work as a mate
   * - justMates {boolean}: Do not need a vehicle, only require mates
   * - movingDate {float}: The moving date represented in milliseconds
   * - discountCode {string}: The discount code string
   */
  fetchDuration(bookingData) {
    return axios
      .request({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}/booking/duration`,
        data: bookingData,
      })
      .then((resp) => resp.data);
  }

  /**
   * Estimation formula for duration of the move
   * @param {object} discountCode The discount used to test the validity
   * @param {string} bookingDate The date of the booking to check discount validity
   */
  fetchDiscount(discountCode, bookingDate) {
    return axios
      .request({
        method: 'POST',
        url: `${process.env.REACT_APP_BACKEND_URL}/discount`,
        data: { discountCode, bookingDate },
      })
      .then((resp) => resp.data);
  }

  createBooking(bookingData, tipData, customerData) {
    return axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/bookings`, {
        bookingRequest: bookingData,
        tipRequest: tipData,
        customerPaymentDetails: customerData,
      })
      .then((resp) => resp.data);
  }

  sendQuote(email, details) {
    return axios
      .post(`${process.env.REACT_APP_BACKEND_URL}/quotes/`, {
        email,
        details,
      })
      .then((resp) => resp.data);
  }

  getQuote(quoteId) {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/quotes/${encodeURIComponent(
          quoteId
        )}`
      )
      .then((resp) => resp.data);
  }

  updateQuote(email, details) {
    return axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/quotes/${details.quote.id}?sendEmail=true`,
        {
          email,
          details,
        }
      )
      .then((resp) => resp.data);
  }

  async fetchPreviousBookings(page, perPage = 5, accessToken) {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/bookings/previous?page=${page}&per_page=${perPage}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    return response.data;
  }

  async fetchUpcomingBookings(page, perPage = 5, accessToken) {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/bookings/upcoming?page=${page}&per_page=${perPage}`,
      {
        headers: { Authorization: `Bearer ${accessToken}` },
      }
    );
    return response.data;
  }

  getSearchBookings(value, page, perPage, accessToken) {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/search?value=${value}&page=${page}&per_page=${perPage}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((resp) => resp.data);
  }

  getFilterBookings(filterDict, page, perPage, accessToken) {
    let query = '';
    for (const [key, value] of Object.entries(filterDict)) {
      query += `&${key}=${JSON.stringify(value)}`;
    }

    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/filter?page=${page}&per_page=${perPage}${query}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((resp) => resp.data);
  }

  getSearchFilteredBookings(value, filterDict, page, perPage, accessToken) {
    let query = '';
    for (const [key, value] of Object.entries(filterDict)) {
      query += `&${key}=${JSON.stringify(value)}`;
    }

    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/searchFiltered?value=${value}&page=${page}&per_page=${perPage}${query}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((resp) => resp.data);
  }

  getBookings(page, perPage = 10, accessToken) {
    return axios
      .get(
        `${process.env.REACT_APP_BACKEND_URL}/bookings?page=${page}&per_page=${perPage}`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((resp) => resp.data);
  }

  /**
   * Allow Ops Team to view a booking with the given ID.
   * @param {string} bookingId
   * @param {string} accessToken
   */
  async opsGetBookingById(bookingId, accessToken) {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/bookings/${bookingId}`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data;
  }

  getBookingById(bookingId) {
    return axios
      .get(`${process.env.REACT_APP_BACKEND_URL}/c/bookings/${bookingId}`, {})
      .then((resp) => resp.data);
  }

  getBookingData(email, shortId) {
    return axios
      .get(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/visitor/bookings?email=${encodeURIComponent(
          email
        )}&shortId=${shortId}`,
        {}
      )
      .then((resp) => resp.data);
  }

  deleteBooking(id, accessToken) {
    return axios
      .delete(`${process.env.REACT_APP_BACKEND_URL}/bookings/${id}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => resp.data);
  }

  editBooking(cipher, bookingData) {
    return axios
      .put(
        `${
          process.env.REACT_APP_BACKEND_URL
        }/c/bookings?email=${encodeURIComponent(bookingData.email)}&shortId=${
          bookingData.shortId
        }`,
        {
          cipher: cipher,
          booking: bookingData,
        }
      )
      .then((resp) => resp.data);
  }

  /**
   * Updates the status for the booking
   * @param {*} bookingData the necessary information required to complete the formula
   *                    id {string} the id of the booking to be edited
   *                    status {string} the new status for the booking
   * @param {string} accessToken The current user access token to ping the backend
   * @returns the changed status
   */
  updateBookingStatus({ id, status, language }, accessToken) {
    return axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/bookings/status/${id}`,
        { status, language },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )
      .then((resp) => resp.data);
  }

  /**
   * Updates the status for the booking to cancelled
   * @param {string} id  the id of the booking to be cancelled
   * @params {object} authData the necessary information required to identify the user
   * @returns the changed status
   */
  cancelBooking(id, { accessToken, ...guestData }) {
    return axios
      .put(
        `${process.env.REACT_APP_BACKEND_URL}/c/bookings/cancel/${id}`,
        guestData,
        {
          headers: accessToken
            ? { Authorization: `Bearer ${accessToken}` }
            : undefined,
        }
      )
      .then((resp) => resp.data);
  }

  /**
   *
   * @param {string} bookingId id of booking
   * @param {Object} booking contains all the fields of the booking to update with
   * @returns {Promise} of response from backend
   */
  async updateBooking(bookingId, booking, accessToken) {
    const res = await axios.put(
      `${process.env.REACT_APP_BACKEND_URL}/bookings/${bookingId}`,
      booking,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return res.data;
  }

  async sendLead(leadData) {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/bookings/leads`,
      leadData,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    return res.data;
  }
}
