const bookingState = {
  quote: {},
  packingBoxes: 0,
  partnerAddons: [],
  addonViewed: false,
  quoteLoading: false,
  tookanOrderID: null,
  resourceId: null,
  createBookingsError: null,

  estimatedDuration: 0,
  isDurationWithinLimit: true,

  guidelinesAgreed: false,

  date: null,
  time: null,

  pickup: {
    country: '',
    province: '',
    postalCode: '',
    city: '',
    street: '',
    unit: '',
    fullAddress: '',
  },
  pickupCoords: null,
  pickupAptUnit: '',
  pickupHasElevator: 0,
  pickupFloor: 0,
  pickupHasParking: false,
  pickupDetails: '',

  dropoff: {
    country: '',
    province: '',
    postalCode: '',
    city: '',
    street: '',
    unit: '',
    fullAddress: '',
  },
  dropoffCoords: null,
  dropoffAptUnit: '',
  dropoffHasElevator: 0,
  dropoffFloor: 0,
  dropoffHasParking: false,
  dropoffDetails: '',

  comments: '',

  bookingType: null,

  selectedFurnitures: [], // [{value:'', quantity:'', category: ''}...]
  rooms: [],
  boxes: 0,

  discount: null, // {code: str, start_date: date, end_date: date, is_percentage: true, discount: number}
  discountCode: null,

  priceQuote: null,
  priceTaxed: null,
  mateCount: 0,

  clientAsMate: false,
  justMates: false,
  rideAlong: false,
  comment: '',

  estimate: {
    discount: null,
    originalAmount: 0,
    amount: 0,
    currency: 'CAD',
    tax: 0,
    longDistance: false,
  },

  bookings: [],
  totalBookings: 0,
  getBookingsLoading: false,
  getBookingsError: null,

  cancelBookingLoading: false,
  cancelBookingError: null,
  cancelBookingSuccess: false,

  billingInformation: {},

  shortId: '',
  id: '',

  lead: {
    submitted: false,
    loading: false,
    error: null,
  },
};

export default bookingState;
