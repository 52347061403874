import * as actions from '../constants/geo';
import salesTax from 'sales-tax';
import { provinceMap } from '../../utils/provinces';
import MapboxService from '../../services/MapboxService/';
import moment from 'moment-timezone';
import { getTimeZoneByCoords } from '../../utils/getAddressDetail';
import api from '../../api';

const mapboxService = new MapboxService();

export const getGeoLocation = (coords) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: actions.FETCH_GEO_LOCATION,
      });
      const geoDataRes = await mapboxService.reverseGeocode(coords);
      const detailedAddress = mapboxService.parseReverseGeo(geoDataRes);
      const data = {
        province: detailedAddress.province,
        proximity: coords,
        timezone: moment.tz.guess(),
      };
      await dispatch({
        type: actions.FETCH_GEO_LOCATION_SUCCESS,
        res: data,
      });
    } catch (error) {
      // if error occurs within geolocation fetch, restore to Montreal settings
      await dispatch({
        type: actions.FETCH_GEO_LOCATION_ERROR,
        error,
      });
      console.error(error);
      await dispatch({
        type: actions.CLEAR_GEO_LOCATION,
      });
    }
  };
};

export const getTaskGeoLocation = (locations) => {
  return async (dispatch) => {
    try {
      await dispatch({
        type: actions.FETCH_TASK_GEO_LOCATION,
      });
      let data = [];
      for (let location in locations) {
        const geoDataRes = await mapboxService.reverseGeocode(
          locations[location]
        );
        data.push({
          location: geoDataRes.data,
        });
      }
      await dispatch({
        type: actions.FETCH_TASK_GEO_LOCATION_SUCCESS,
        data: data,
      });
    } catch (error) {
      console.log(error);
      await dispatch({
        type: actions.FETCH_TASK_GEO_LOCATION_ERROR,
        error,
      });
    }
  };
};

export const getTax = (editMode, adminMode = false) => {
  return async (dispatch, getState) => {
    const { bookingReducer, tempBookingReducer, adminBookingReducer } =
      getState();
    try {
      if (adminMode) {
        const { viewedBooking } = adminBookingReducer;
        const res = await salesTax.getSalesTax(
          'CA',
          provinceMap[viewedBooking.pickupAddress.province]
        );
        await dispatch({
          type: actions.GET_TAX,
          tax: res,
        });
      } else {
        const { pickup } = editMode ? tempBookingReducer : bookingReducer;
        const res = await salesTax.getSalesTax(
          'CA',
          provinceMap[pickup.province]
        );
        await dispatch({
          type: actions.GET_TAX,
          tax: res,
        });
      }
    } catch (error) {
      await dispatch({
        type: actions.FETCH_GEO_LOCATION_ERROR,
        error,
      });
      throw error;
    }
  };
};

export const getTimeZone = (lat, lng) => {
  return async (dispatch) => {
    const timeZoneId = await getTimeZoneByCoords(lat, lng);
    await dispatch({
      type: actions.FETCH_GEO_LOCATION_SUCCESS,
      res: {
        timezone: timeZoneId || moment.tz.guess(),
        proximity: [lng, lat],
      },
    });
  };
};

export const getNearestCityByCoordinates = (direction, lat, lng) => {
  return async (dispatch) => {
    try {
      const { data } = await api.post('/cities/nearest', {
        latitude: lat,
        longitude: lng,
      });
      await dispatch({
        type: actions.FETCH_CITY_GEO_LOCATION_SUCCESS,
        payload: data,
        direction,
      });
    } catch (error) {
      console.error('Error to get nearest city', error.message);
      await dispatch({
        type: actions.FETCH_CITY_GEO_LOCATION_ERROR,
        payload: error,
      });
    }
  };
};

export const getPartnersByCityId = () => {
  return async (dispatch, getState) => {
    try {
      const { geoReducer } = getState();
      // Remove Duplicated City ID
      const citiesIds = [
        ...new Set([geoReducer.pickup.id, geoReducer.dropoff.id]),
      ].filter((city) => city);
      // Get Partner Addons by City ID
      const payload = (
        await Promise.all(
          citiesIds.map(
            async (cityId) => await api.get(`/b2c/partner/addons/${cityId}`)
          )
        )
      ).map((res) => res.data);
      // Convert to One Array of Partner Addons
      const partners = Array.prototype.concat.apply([], payload);
      // Remove Duplicated Partner ID of addons list
      const partnerData = partners.reduce((acc, partner) => {
        if (acc.some((p) => p.id === partner.id)) return acc;
        return acc.concat(partner);
      }, []);
      await dispatch({
        type: actions.FETCH_PARTNER_ADDONS_PER_LOCATION_SUCCESS,
        payload: partnerData,
      });
    } catch (error) {
      console.error('Error to get partner addons by city_id', error.message);
      await dispatch({
        type: actions.FETCH_PARTNER_ADDONS_PER_LOCATION_ERROR,
        payload: error,
      });
    }
  };
};

export const setProvince = (provinceName) => {
  return async (dispatch) => {
    await dispatch({
      type: actions.FETCH_GEO_LOCATION_SUCCESS,
      res: { province: provinceMap[provinceName] || 'QC' },
    });
  };
};
